import { quest } from "@fscrypto/domain";
import { questConfigList, questConfigs, questEcosystemSchema } from "@fscrypto/domain/quest";
import { TaxDocumentationStatus } from "@fscrypto/domain/tax";
import { LoaderFunctionArgs } from "@remix-run/node";
import { Outlet, useParams } from "@remix-run/react";
import { $path } from "remix-routes";
import { EcosystemLinkButton } from "~/features/ecosystem/eco-link";
import { EcosystemDropdown } from "~/features/quests/components/ecosystem-dropdown";
import { json, redirect, useLoaderData } from "~/remix";
import { services } from "~/services/services.server";

export type LoaderData =
  | {
      __type: "unauthenticated";
    }
  | {
      __type: "authenticated";
      taxStatus: TaxDocumentationStatus;
    };

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const currentUser = await services.auth.getCurrentUser(request);

  // Send users to their last viewed earn ecosystem page
  const url = new URL(request.url);
  if (url.pathname === $path("/earn")) {
    if (currentUser) {
      const lastViewedEcosystem = await services.quest.getLastViewedEcosystem(currentUser.id);
      if (lastViewedEcosystem && questConfigs[questEcosystemSchema.parse(lastViewedEcosystem)].visible) {
        return redirect($path("/earn/:ecosystem", { ecosystem: lastViewedEcosystem }), 302);
      }
    }
    return redirect($path("/earn/:ecosystem", { ecosystem: quest.questConfigList[0].name }), 302);
  }

  if (!currentUser) {
    return json({ __type: "unauthenticated" } satisfies LoaderData);
  }

  const taxStatus = await services.tax.getTaxStatus(currentUser.id);

  return json({
    __type: "authenticated",
    taxStatus,
  } satisfies LoaderData);
};

const Index = () => {
  const loaderData = useLoaderData<LoaderData>();
  const params = useParams();

  return (
    <div className="space-y-4 px-3 py-3 md:px-10">
      <div className="space-y-2">
        <h1 className="text-3xl font-bold">Level up and earn big!</h1>
        <p>Complete quests to level up on chain. Higher levels earn more per quest completion.</p>
      </div>

      {/* Desktop */}
      <div className="hidden md:block">
        <div className="mx-auto grid max-w-[800px] grid-cols-3 gap-2 py-3 xl:flex xl:max-w-full xl:justify-center">
          {questConfigList
            .filter((qc) => qc.visible)
            .map((qc) => {
              const ecosystem = questEcosystemSchema.parse(qc.name);
              return (
                <EcosystemLinkButton key={ecosystem} ecosystem={ecosystem} selected={params.ecosystem === ecosystem} />
              );
            })}
        </div>
      </div>

      {/* Mobile */}
      <div className="block md:hidden">
        <EcosystemDropdown ecosystem={params.ecosystem} />
      </div>

      <Outlet context={loaderData} />
    </div>
  );
};
export default Index;
